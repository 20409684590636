import styled from "styled-components";

// Styled Components
const ListContainer = styled.div`
  background-color: #FFFFFF;
  border-radius: 0 0 0.6em 0.6em;
  box-shadow: 0 4px 0.5em -0.4em #000000;
  color: #000000;
  display: ${props => props.visible ? "inline-block" : "none"};
  left: 0;
  // padding: 1em;
  position: absolute;
  top: 3.5em;
  width: 100%;
  z-index: 1;
`;

const ListLink = styled.a`
  cursor: pointer;
  display: inline-block;
  font-family: "Carlito";
  font-size: 1.25em;
  padding: 0.5em 1em;
  position: relative;
  text-align: center;
  width: 100%;
`;

const LocationList = ({ locations, onClick }) => {
  return (
    <ListContainer visible={locations.length > 0}>
      {locations.map((location, index) => {
        let timezone = location.timezone;
        let value = location.value;
        return (
          <ListLink key={index} onClick={(event) => onClick(event, value, timezone)}>{value}</ListLink>
        )
      })}
    </ListContainer>
  );
}

export default LocationList;
