const GiftTypeData = {
  "Generator": {
    label: "My gift is to create and lift up the energy of the world"
  },
  "Projector": {
    label: "My gift is to guide others and bring efficiency to the world"
  },
  "Reflector": {
    label: "My gift is my ability to express myself in a multitude of ways"
  },
  "Manifestor": {
    label: "My gift is to bring new ideas and ways of doing things to the world"
  },
  "Manifesting Generator": {
    label: "My gift is to master multiple passions & reinvent myself along the way"
  }
};

export default GiftTypeData;
