import styled from 'styled-components';

// Assets
import Logo from "../assets/images/dyd-logo-full-2.png"

// Styled Elements
const HeaderElement = styled.div`
  align-content: center;
  display: flex;
  justify-content: center;
  margin-bottom: 6em;
  position: relative;
  width: 100%;
`;

const LogoLink = styled.a`
  background-image: url('${Logo}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  padding-bottom: 13em;
  position: relative;
  text-indent: -9999px;
  width: 100%;
`;

function Header() {
  return (
    <HeaderElement>
      <LogoLink aria-label="The Design of You" href="https://www.thedesignofyou.com/" title="The Design of You">
        The Design of You
      </LogoLink>
    </HeaderElement>
  );
}

export default Header;
