import styled from "styled-components";

export const H1 = styled.h1`
  font-family: 'Tan-Mon-Cheri';
  font-size: 1.875em;
  font-weight: 100;
  margin-bottom: 1.75em;
  text-transform: uppercase;

  &.large {
    font-size: 4em;
  }
`;

export const H2 = styled.h2`
  font-family: 'Bebas-Neue';
  font-size: 2.438em;
  font-weight: 100;
  line-height: 1.2;
  margin-bottom: 0.2em;
  text-transform: uppercase;

  &.alt {
    font-family: 'Tan-Mon-Cheri';
    font-size: 1.875em;
  }
`;

export const H3 = styled.h3`
  font-family: 'Carlito-I';
  font-size: 1.313em;
  font-weight: 400;
  line-height: 1;
  // margin-bottom: 0.875em;
`;
