const ProfileData = {
  "1 / 3": {
    "1": {
      label: "I love to research and understand things",
      subtext: "1/3 - Line 1"
    },
    "3": {
      label: "and learn by experience at the same time",
      subtext: "1/3 - Line 3"
    }
  },
  "1 / 4": {
    "1": {
      label: "I love to research new things",
      subtext: "1/4 - Line 1"
    },
    "4": {
      label: "and share what I have learned with others",
      subtext: "1/4 - Line 4"
    }
  },
  "2 / 4": {
    "2": {
      label: "I enjoy my alone time",
      subtext: "2/4 - Line 2"
    },
    "4": {
      label: "but the right people and connections bring me joy",
      subtext: "2/4 - Line 4"
    }
  },
  "2 / 5": {
    "2": {
      label: "I love my independence",
      subtext: "2/5 - Line 2"
    },
    "5": {
      label: "but I naturally enjoy helping others",
      subtext: "2/5 - Line 5"
    }
  },
  "3 / 5": {
    "3": {
      label: "I love to experiment with all life has to offer",
      subtext: "3/5 - Line 3"
    },
    "5": {
      label: "and I help others through my life lessons",
      subtext: "3/5 - Line 5"
    }
  },
  "3 / 6": {
    "3": {
      label: "I love to experiment with all life has to offer",
      subtext: "3/6 - Line 3"
    },
    "6": {
      label: "and people recognize me as a role model",
      subtext: "3/6 - Line 6"
    }
  },
  "4 / 1": {
    "4": {
      label: "I love making connections in life",
      subtext: "4/1 - Line 4"
    },
    "1": {
      label: "but I equally desire to be introspective and steadfast",
      subtext: "4/1 - Line 1"
    }
  },
  "4 / 6": {
    "4": {
      label: "I love making connections",
      subtext: "4/6 - Line 4"
    },
    "6": {
      label: "and I influence others through my life experiences",
      subtext: "4/6 - Line 6"
    }
  },
  "5 / 1": {
    "5": {
      label: "I desire to help to others",
      subtext: "5/1 - Line 5"
    },
    "1": {
      label: "and my strong foundation of knowledge is how I serve",
      subtext: "5/1 - Line 1"
    }
  },
  "5 / 2": {
    "5": {
      label: "I love meaningful interactions",
      subtext: "5/2 - Line 5"
    },
    "2": {
      label: "but I equally enjoy my independence at the same time",
      subtext: "5/2 - Line 2"
    }
  },
  "6 / 2": {
    "6": {
      label: "I love serving as a role model for others",
      subtext: "6/2 - Line 6"
    },
    "2": {
      label: "and people tend to recognize my talents before I do",
      subtext: "6/2 - Line 2"
    }
  },
  "6 / 3": {
    "6": {
      label: "I love to share my experiential wisdom with others",
      subtext: "6/3 - Line 6"
    },
    "3": {
      label: "and people recognize me as a lifelong learner",
      subtext: "6/3 - Line 3"
    }
  }
};

export default ProfileData;
