import styled from "styled-components";

// Components
import downChevron from '../../assets/images/chevron-down.svg';

// Styled Components
const Select = styled.select`
  appearance: none; // Reset for mobile user agent styles
  background-color: #FFFFFF;
  background-image: url(${downChevron});
  background-position: right 16px center;
  background-repeat: no-repeat;
  background-size: 12px;
  // border-radius: 0.6em;
  box-shadow: 0 4px 0.5em -0.6em #000000;
  color: #000000;
  font-family: "Carlito";
  font-size: 1.25em;
  line-height: 1.2;
  margin: 0 0 1.5em;
  padding: 1em 1.8em 1em 1em;
  text-align: center;
  text-align: -webkit-center;
  width: 100%;

  &:focus, &:focus-visible {
    outline: inset;
  }
`;

const Option = styled.option`
  //
`;

const SelectInput = ({ name, options, onChange, placeholder, required = false, value }) => {
  const changeEvent = (event) => {
    value = (event.target.value);
    onChange && onChange(event);
  }

  const typeCheck = (value) => {
    return (typeof value === "string") || (typeof value === "number");
  }

  if (placeholder) {
    options = [{ text: placeholder, value: "", disabled: true}, ...options];
  }

  return (
    <Select
      id={name}
      name={name}
      onChange={changeEvent}
      placeholder={placeholder}
      required={required}
      value={value || (typeCheck(options[0]) ? options[0] : options[0].value)}
    >
      {options.map((option) => {
        const text = typeCheck(option) ? option : option.text;
        const value = typeCheck(option) ? option : option.value;

        return (
          <Option disabled={option?.disabled} key={value} value={value}>
            {text}
          </Option>
        )
      })}
    </Select>
  );
}

export default SelectInput;
