const IncarnationCrossData = {
  "Right Angle Cross of The Sphinx": {
    label: "My purpose is to guide others toward all that is possible"
  },
  "Right Angle Cross of Explanation": {
    label: "My purpose is to explain things in a genius way"
  },
  "Right Angle Cross of Contagion": {
    label: "My purpose is to inspire others through my enthusiastic energy"
  },
  "Right Angle Cross of The Sleeping Phoenix": {
    label: "My purpose is to stay busy doing things that ignite my soul"
  },
  "Right Angle Cross of Planning": {
    label: "My purpose is to contribute to my community and plan for success"
  },
  "Right Angle Cross of Consciousness": {
    label: "My purpose is to raise the consciousness of the world"
  },
  "Right Angle Cross of Rulership": {
    label: "My purpose is to be a natural leader"
  },
  "Right Angle Cross of Eden": {
    label: "My purpose is to explore the world and find my own version of paradise"
  },
  "Right Angle Cross of The Vessel of Love": {
    label: "My purpose is to be universally loving to all things"
  },
  "Right Angle Cross of Service": {
    label: "My purpose is to serve people through organization and leadership"
  },
  "Right Angle Cross of Tension": {
    label: "My purpose is to push people into new ways of thinking"
  },
  "Right Angle Cross of Penetration": {
    label: "My purpose is to follow my ambitions and get straight to the point"
  },
  "Right Angle Cross of Maya": {
    label: "My purpose is to notice the small details and share them with others"
  },
  "Right Angle Cross of Laws": {
    label: "My purpose is to set rules and create laws for organization"
  },
  "Right Angle Cross of The Unexpected": {
    label: "My purpose is to support others and handle the unexpected for them"
  },
  "Right Angle Cross of The Four Ways": {
    label: "My purpose is to make sure others are taken care of"
  },
  "Left Angle Cross of Masks": {
    label: "My purpose is to take the lead, give direction, and follow through greatly"
  },
  "Left Angle Cross of Revolution": {
    label: "My purpose is to create change for the common good"
  },
  "Left Angle Cross of Industry": {
    label: "My purpose is to be productive with things I am passionate about"
  },
  "Left Angle Cross of Spirit": {
    label: "My purpose is to fuel my spirit with the pleasures that feel good to me"
  },
  "Left Angle Cross of Migration": {
    label: "My purpose is to break free from what everyone else is doing"
  },
  "Left Angle Cross of Dominion": {
    label: "My purpose is to step in and be a powerful leader"
  },
  "Left Angle Cross of Informing": {
    label: "My purpose is to hold space for others to be vulnerable for the greater good"
  },
  "Left Angle Cross of The Plane": {
    label: "My purpose is to be an earthly guide for others to tap into their intuition"
  },
  "Left Angle Cross of Healing": {
    label: "My purpose is to embody and heal others through love and wellness"
  },
  "Left Angle Cross of Upheaval": {
    label: "My purpose is to stir things up in an effort to make the world better"
  },
  "Left Angle Cross of Endeavor": {
    label: "My purpose is to inspire depth and make big things happen in the world"
  },
  "Left Angle Cross of The Clarion": {
    label: "My purpose is to bring intuitive change to those who are ready"
  },
  "Left Angle Cross of Limitation": {
    label: "My purpose is to bring structure and clarity to others"
  },
  "Left Angle Cross of Wishes": {
    label: "My purpose is to lead change through my wish for a better future"
  },
  "Left Angle Cross of Alignment": {
    label: "My purpose is to shift from old thinking and align with the new"
  },
  "Left Angle Cross of Incarnation": {
    label: "My purpose is to observe cycles and guide others through change"
  },
  "Left Angle Cross of Defiance": {
    label: "My purpose is to defy the odds and embody self-expression"
  },
  "Left Angle Cross of Dedication": {
    label: "My purpose is to teach in an insightful and explanatory way"
  },
  "Left Angle Cross of Uncertainty": {
    label: "My purpose is to bring awareness to life's uncertainties"
  },
  "Left Angle Cross of Duality": {
    label: "My purpose is to find a balance between being an individual and a part of my community"
  },
  "Left Angle Cross of Identification": {
    label: "My purpose is to draw others towards worthy causes"
  },
  "Left Angle Cross of Separation": {
    label: "My purpose is to accept and love others who are different from me"
  },
  "Left Angle Cross of Confrontation": {
    label: "My purpose is to confront what isn't working and bring justice"
  },
  "Left Angle Cross of Education": {
    label: "My purpose is to encourage the importance of education"
  },
  "Left Angle Cross of Prevention": {
    label: "My purpose is to lovingly prevent others from harmful situations"
  },
  "Left Angle Cross of Demands": {
    label: "My purpose is to demand action for what isn't working in society"
  },
  "Left Angle Cross of Individualism": {
    label: "My purpose is to push people towards their individual purpose"
  },
  "Left Angle Cross of Cycles": {
    label: "My purpose is to seamlessly handle the ever-changing cycles of life"
  },
  "Left Angle Cross of Obscuration": {
    label: "My purpose is to examine life and bring new concepts to the collective"
  },
  "Left Angle Cross of Distraction": {
    label: "My purpose is to distract others from life's worries"
  },
  "Left Angle Cross of The Alpha": {
    label: "My purpose is to take care of others through my natural leadership"
  },
  "Left Angle Cross of Refinement": {
    label: "My purpose is to refine the world in an upgraded way"
  },
  "Juxtaposition Cross of Listening": {
    label: "My purpose is to be an attentive listener"
  },
  "Juxtaposition Cross of Principles": {
    label: "My purpose is to stand up for human rights"
  },
  "Juxtaposition Cross of Fates": {
    label: "My purpose is to be intensely driven and passionate in life"
  },
  "Juxtaposition Cross of Moods": {
    label: "My purpose is to focus my energy is a deep and detailed way"
  },
  "Juxtaposition Cross of Bargains": {
    label: "My purpose is to make deals and bargains with others"
  },
  "Juxtaposition Cross of Doubts": {
    label: "My purpose is to question things and process them logically"
  },
  "Juxtaposition Cross of Grace": {
    label: "My purpose is to offer grace and attention to others through listening"
  },
  "Juxtaposition Cross of Crisis": {
    label: "My purpose is to create intimate spaces for the people I love"
  },
  "Juxtaposition Cross of Innocence": {
    label: "My purpose is to influence the world through my love of life"
  },
  "Juxtaposition Cross of Opinions": {
    label: "My purpose is to offer my opinion"
  },
  "Juxtaposition Cross of Control": {
    label: "My purpose is to bring innovation by taking control of certain situations"
  },
  "Juxtaposition Cross of Shock": {
    label: "My purpose is to shock others out of complacency"
  },
  "Juxtaposition Cross of Completion": {
    label: "My purpose is to complete things that others have given up on"
  },
  "Juxtaposition Cross of Mutation": {
    label: "My purpose is to bring change to current rules or laws"
  },
  "Juxtaposition Cross of Caring": {
    label: "My purpose is to bring a sense of caring to all people and things"
  },
  "Juxtaposition Cross of Rationalization": {
    label: "My purpose is to understand complex concepts and old patterns to lead others"
  },
  "Juxtaposition Cross of The Driver": {
    label: "My purpose is to follow my own truth and direction in life"
  },
  "Juxtaposition Cross of Assimilation": {
    label: "My purpose is to bring individual ideas forward to create change"
  },
  "Juxtaposition Cross of Contribution": {
    label: "My purpose is to contribute to society through demonstration"
  },
  "Juxtaposition Cross of The Now": {
    label: "My purpose is to embody the gift of staying present"
  },
  "Juxtaposition Cross of Experimentation": {
    label: "My purpose is to be fiercely determined to achieve my goals"
  },
  "Juxtaposition Cross of Experience": {
    label: "My purpose is to seek out very specific and unique experiences in life"
  },
  "Juxtaposition Cross of Possession": {
    label: "My purpose is to influence and guide important people in my life"
  },
  "Juxtaposition Cross of Articulation": {
    label: "My purpose is to articulate words with love for positive change"
  },
  "Juxtaposition Cross of Extremes": {
    label: "My purpose is to embrace extreme ways of living"
  },
  "Juxtaposition Cross of Stillness": {
    label: "My purpose is to give wise advice or ideas when asked for"
  },
  "Juxtaposition Cross of Provocation": {
    label: "My purpose is to intuitively provoke others to change"
  },
  "Juxtaposition Cross of Beginnings": {
    label: "My purpose is to take on projects and complete them successfully"
  },
  "Juxtaposition Cross of Detail": {
    label: "My purpose is to contribute ideas in a detailed way"
  },
  "Juxtaposition Cross of Stimulation": {
    label: "My purpose is to follow the adrenaline and thrills in life"
  },
  "Juxtaposition Cross of Influence": {
    label: "My purpose is to influence the world through my persistence"
  },
  "Juxtaposition Cross of Retreat": {
    label: "My purpose is to create sacred spaces for people to be themselves"
  },
  "Juxtaposition Cross of Interaction": {
    label: "My purpose is to be a leader, whether directly or indirectly"
  },
  "Juxtaposition Cross of Formulization": {
    label: "My purpose is to lay the foundation for patterns and formulas"
  },
  "Juxtaposition Cross of Commitment": {
    label: "My purpose is to have powerful devotion to my passions"
  },
  "Juxtaposition Cross of Strategy": {
    label: "My purpose is to understand relationship dynamics"
  },
  "Juxtaposition Cross of Denial": {
    label: "My purpose to be a voice of concern when things get out of hand"
  },
  "Juxtaposition Cross of Confusion": {
    label: "My purpose is to be an objective thinker and narrate life's events"
  },
  "Juxtaposition Cross of Oppression": {
    label: "My purpose is to bring new ideas to the world for others to complete"
  },
  "Juxtaposition Cross of Conflict": {
    label: "My purpose is to find joy in networking and establishing friendships"
  },
  "Juxtaposition Cross of Serendipity": {
    label: "My purpose is to enjoy the magic of how life perfectly falls into place"
  },
  "Juxtaposition Cross of Correction": {
    label: "My purpose is to correct patterns and discover a more joyful life"
  },
  "Juxtaposition Cross of Depth": {
    label: "My purpose is to inspire others through depth"
  },
  "Juxtaposition Cross of Intuition": {
    label: "My purpose is to intuitively read others in times of need"
  },
  "Juxtaposition Cross of Conservation": {
    label: "My purpose is to conserve and preserve life"
  },
  "Juxtaposition Cross of Values": {
    label: "My purpose is to establish order through rules and values"
  },
  "Juxtaposition Cross of Risks": {
    label: "My purpose is to take and enjoy the adrenaline of risks"
  },
  "Juxtaposition Cross of Alertness": {
    label: "My purpose is to recognize what is about to happen before it happens"
  },
  "Juxtaposition Cross of Self-expression": {
    label: "My purpose is to be different and do my own, unique thing"
  },
  "Juxtaposition Cross of Insight": {
    label: "My purpose is to put abstract information together to create powerful insights"
  },
  "Juxtaposition Cross of Empowering": {
    label: "My purpose is to feel empowered through happiness and security"
  },
  "Juxtaposition Cross of Power": {
    label: "My purpose is to bring big powerful, soulful energy to everything I do"
  },
  "Juxtaposition Cross of Focus": {
    label: "My purpose is to be hyper focused in a calm way"
  },
  "Juxtaposition Cross of Habits": {
    label: "My purpose is to follow routines and rituals that enhance my life"
  },
  "Juxtaposition Cross of The Trickster": {
    label: "My purpose is to rally others around new ideas and get them excited"
  },
  "Juxtaposition Cross of Ideas": {
    label: "My purpose is to be express my ideas about humanity in a philosophical way"
  },
  "Juxtaposition Cross of Behavior": {
    label: "My purpose is to guide and correct the behavior of others for the better"
  },
  "Juxtaposition Cross of Vitality": {
    label: "My purpose is to bring energy to logical thinking"
  },
  "Juxtaposition Cross of Opposition": {
    label: "My purpose is to a natural skeptic and push others to think differently"
  },
  "Juxtaposition Cross of Ambition": {
    label: "My purpose is to be fiercely ambitious until my dreams are a reality"
  },
  "Juxtaposition Cross of Thinking": {
    label: "My purpose is to understand how things work and move it along"
  },
  "Juxtaposition Cross of Limitation": {
    label: "My purpose is to follow the rules and preserve tradition"
  },
  "Juxtaposition Cross of Fantasy": {
    label: "My purpose is to spot trends and fantasize about aesthetics"
  },
  "Juxtaposition Cross of Need": {
    label: "My purpose is to be creative and expressive in my own way"
  }
};

export default IncarnationCrossData;
