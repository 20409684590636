import styled from "styled-components";

// Assets
import { black, white } from "../utilities/brandColors";

// Styled Components
const Link = styled.a`
  background-color: ${black};
  // border-radius: 0.6em;
  box-shadow: 0 4px 0.5em -0.4em #000000;
  color: ${white};
  cursor: pointer;
  display: inline-block;
  font-family: "Bebas-Neue";
  font-size: 1.625em;
  // max-width: 20em;
  padding: 0.75em 2em;
  text-transform: uppercase;
  transition: 0.3s ease-in-out all;

  ${props => props.full && `
    margin-bottom: 1em;
    width: 100%;
  `}

  &:hover {
    background-color: ${white};
    color: ${black};
  }
`;

const Cta = ({ full = false, href, target = "_blank", text }) => {
  return (
    <Link full={full} href={href} target={target}>{text}</Link>
  );
}

export default Cta;
