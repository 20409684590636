import IconBase from "./IconBase";

const LogoText = ({ fill = "#000000", height = "181px", width = "1347px" }) => (
  <IconBase
    fill={fill}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 1860 421"
    width={width}
  >
    <g transform="translate(0, 421) scale(0.1, -0.1)" stroke="none">
      <path d="M15582 4151 c-135 -37 -235 -95 -330 -193 -110 -113 -115 -124 -378 -845 -114 -315 -211 -573 -214 -573 -5 0 -78 192 -285 753 -61 165 -62 167 -39 167 17 0 24 6 24 20 0 19 -7 20 -165 20 -139 0 -165 -2 -165 -15 0 -15 36 -29 59 -23 8 2 25 -30 46 -87 19 -49 101 -272 184 -494 l151 -405 0 -493 0 -493 -30 0 c-20 0 -30 -5 -30 -15 0 -13 25 -15 165 -15 140 0 165 2 165 15 0 10 -10 15 -30 15 l-30 0 0 489 0 489 206 564 c113 309 219 600 236 646 62 171 183 309 333 383 105 51 181 70 290 71 123 1 193 -15 305 -69 172 -82 303 -242 350 -429 130 -506 -348 -957 -847 -799 -60 18 -83 31 -83 42 0 31 79 180 138 259 140 189 338 307 541 322 71 6 86 10 89 25 3 15 -5 17 -61 17 -133 0 -311 -48 -443 -119 -418 -226 -627 -698 -510 -1156 47 -183 134 -335 270 -471 128 -127 275 -215 446 -264 95 -28 342 -39 457 -20 130 21 266 75 393 158 83 54 240 216 294 304 273 443 178 1016 -224 1341 -91 73 -237 152 -336 182 l-61 18 -7 70 c-21 228 -173 449 -381 555 -94 47 -166 64 -295 68 -102 3 -138 1 -198 -15z m1011 -798 c168 -102 313 -298 387 -522 37 -114 52 -220 52 -363 -2 -671 -550 -1140 -1080 -925 -126 52 -268 168 -358 293 -61 85 -128 231 -161 350 -26 92 -28 113 -28 284 0 147 4 201 19 261 10 42 21 82 25 87 4 8 28 3 71 -12 402 -146 844 105 926 526 l17 87 35 -14 c19 -8 61 -31 95 -52z"/>
      <path d="M1187 3553 c-17 -16 -5 -33 23 -33 l30 0 0 -960 0 -960 -30 0 c-24 0 -30 -4 -30 -21 0 -21 1 -21 203 -16 175 4 215 8 301 31 323 84 586 327 692 640 162 477 -52 997 -501 1216 -118 58 -215 86 -345 100 -108 11 -333 13 -343 3z m441 -65 c141 -43 260 -123 364 -246 253 -296 312 -779 140 -1145 -79 -168 -176 -285 -313 -377 -98 -65 -211 -107 -316 -117 l-63 -6 0 963 0 963 61 -7 c33 -4 91 -16 127 -28z"/>
      <path d="M2625 3550 c-10 -16 4 -30 31 -30 l24 0 0 -960 0 -960 -23 0 c-29 0 -37 -7 -30 -26 4 -12 90 -14 539 -14 l534 0 0 170 c0 163 -1 170 -20 170 -16 0 -20 -7 -20 -33 0 -95 -67 -192 -164 -238 l-61 -29 -272 0 -273 0 -2 470 -3 470 170 0 c157 0 174 -2 215 -22 52 -26 88 -75 96 -130 5 -28 11 -38 25 -38 18 0 19 11 19 210 0 203 -1 210 -20 210 -15 0 -20 -7 -20 -24 0 -59 -48 -127 -107 -152 -23 -10 -80 -14 -203 -14 l-170 0 0 470 0 470 258 0 c289 0 328 -6 403 -64 54 -41 99 -121 108 -189 4 -38 11 -53 24 -55 16 -3 17 10 17 172 l0 176 -534 0 c-346 0 -537 -4 -541 -10z"/>
      <path d="M4352 3549 c-177 -30 -327 -141 -405 -299 -27 -55 -32 -75 -32 -140 0 -131 2 -133 359 -432 171 -144 346 -290 388 -326 125 -105 169 -185 176 -323 3 -65 0 -101 -13 -140 -82 -254 -364 -362 -617 -237 -157 78 -278 267 -278 434 0 28 -4 35 -17 32 -17 -3 -18 -25 -21 -276 -2 -262 -2 -272 17 -272 14 0 21 9 25 34 15 80 108 110 182 59 152 -103 335 -129 498 -69 169 61 289 192 341 371 24 81 17 247 -14 326 -27 71 -68 133 -123 187 -24 23 -200 173 -392 334 -361 303 -382 325 -393 406 -13 99 46 181 180 247 191 95 417 59 569 -89 90 -88 140 -190 154 -316 4 -41 11 -60 20 -60 12 0 14 49 14 275 0 227 -2 275 -14 275 -7 0 -16 -14 -20 -31 -3 -17 -19 -44 -35 -60 -44 -43 -93 -40 -179 11 -114 69 -254 99 -370 79z"/>
      <path d="M5210 3540 c0 -16 7 -20 30 -20 l30 0 0 -960 0 -960 -30 0 c-23 0 -30 -4 -30 -20 0 -19 7 -20 160 -20 153 0 160 1 160 20 0 16 -7 20 -30 20 l-30 0 0 960 0 960 30 0 c23 0 30 4 30 20 0 19 -7 20 -160 20 -153 0 -160 -1 -160 -20z"/>
      <path d="M7957 3553 c-17 -16 -5 -33 23 -33 l30 0 0 -960 0 -960 -30 0 c-23 0 -30 -4 -30 -20 0 -19 7 -20 195 -20 184 0 195 1 195 19 0 14 -10 20 -45 24 -89 13 -173 76 -216 164 l-24 48 -2 825 c-2 767 -1 823 14 795 9 -16 126 -230 261 -475 135 -245 284 -517 333 -605 48 -88 166 -302 261 -475 l174 -315 97 -3 97 -3 0 981 0 980 30 0 c23 0 30 4 30 20 0 20 -5 20 -192 18 -164 -3 -193 -5 -196 -18 -2 -12 12 -19 56 -28 102 -21 197 -107 222 -202 7 -24 9 -327 8 -841 l-3 -803 -187 340 c-530 965 -831 1511 -844 1532 -14 21 -21 22 -133 22 -65 0 -121 -3 -124 -7z"/>
      <path d="M6545 3531 c-387 -91 -681 -389 -760 -771 -75 -363 61 -744 348 -975 102 -83 270 -167 392 -197 136 -33 353 -31 488 5 275 73 499 252 634 507 66 123 68 118 71 -227 2 -295 3 -303 22 -303 20 0 20 8 20 505 l0 505 -420 0 c-413 0 -420 0 -420 -20 0 -20 7 -20 320 -20 l320 0 0 -27 c0 -54 -29 -219 -51 -288 -87 -282 -287 -507 -527 -590 -104 -36 -257 -45 -367 -20 -223 49 -433 229 -545 467 -109 233 -135 514 -69 763 131 503 581 786 998 629 218 -82 409 -291 497 -542 l23 -67 96 -3 c112 -3 108 -12 50 115 -97 211 -298 407 -508 496 -133 56 -198 69 -372 73 -131 3 -175 0 -240 -15z"/>
      <path d="M10920 3481 c-203 -45 -353 -125 -504 -270 -92 -89 -149 -166 -204 -277 -222 -440 -93 -982 302 -1276 93 -69 230 -138 336 -169 93 -27 365 -38 463 -20 392 74 706 369 808 759 26 100 36 292 20 401 -31 213 -135 418 -290 572 -91 90 -172 146 -295 205 -141 66 -225 85 -401 90 -121 3 -167 0 -235 -15z m389 -41 c303 -84 538 -366 617 -740 24 -114 24 -324 1 -443 -60 -303 -246 -570 -477 -687 -374 -188 -797 -29 -1015 383 -162 305 -168 711 -15 1018 124 251 306 410 543 474 81 22 258 19 346 -5z"/>
      <path d="M11102 2700 c-7 -101 -15 -186 -17 -188 -2 -2 -23 14 -47 35 -75 68 -84 70 -30 7 28 -33 52 -63 52 -66 0 -3 -82 -11 -182 -18 -101 -6 -184 -13 -186 -15 -1 -1 46 -5 105 -9 59 -4 145 -9 190 -13 l83 -6 -63 -71 -62 -71 68 59 c37 32 70 57 72 54 2 -2 10 -87 17 -188 14 -205 20 -195 33 56 4 74 10 134 13 134 3 0 33 -24 66 -52 64 -55 60 -43 -8 29 -22 24 -38 45 -35 47 4 5 286 26 341 26 17 0 29 2 27 4 -2 2 -85 10 -184 16 -99 7 -182 14 -184 16 -3 3 11 21 30 42 75 81 81 93 18 39 -36 -31 -69 -57 -71 -57 -3 0 -9 60 -13 134 -13 251 -19 261 -33 56z"/>
      <path d="M12352 3483 c2 -12 13 -18 31 -18 l27 1 0 -988 0 -988 -30 0 c-20 0 -30 -5 -30 -15 0 -13 25 -15 165 -15 140 0 165 2 165 15 0 10 -10 15 -30 15 l-30 0 0 485 0 485 173 0 c186 0 226 -9 274 -61 26 -28 53 -89 53 -119 0 -13 7 -20 20 -20 19 0 20 7 20 214 0 201 -1 214 -19 219 -16 4 -20 -3 -25 -37 -9 -56 -48 -112 -96 -136 -35 -18 -58 -20 -220 -20 l-180 0 0 480 0 480 288 0 c267 0 290 -2 332 -21 91 -41 165 -141 177 -240 5 -39 10 -49 24 -49 18 0 19 11 19 175 l0 175 -556 0 c-524 0 -555 -1 -552 -17z"/>
      <path d="M17440 3480 c0 -16 7 -20 30 -20 l29 0 3 -797 3 -798 26 -66 c39 -101 108 -191 191 -249 94 -66 172 -90 292 -90 147 0 256 43 356 140 66 65 106 130 135 223 l25 78 0 780 0 779 30 0 c23 0 30 4 30 20 0 19 -7 20 -196 20 -180 0 -195 -1 -192 -17 2 -12 17 -20 45 -25 87 -14 171 -77 215 -161 l23 -42 3 -674 c3 -735 4 -719 -55 -835 -38 -76 -125 -161 -205 -200 -172 -87 -329 -69 -437 50 -91 99 -86 39 -86 1014 l0 854 30 1 c20 0 31 6 33 18 3 16 -10 17 -162 17 -159 0 -166 -1 -166 -20z"/>
      <path d="M10 3315 l0 -175 330 0 330 0 0 175 0 175 -45 0 -45 0 0 -130 0 -130 -100 0 -100 0 0 105 0 105 -40 0 -40 0 0 -105 0 -105 -105 0 -105 0 0 130 0 130 -40 0 -40 0 0 -175z"/>
      <path d="M10 2735 l0 -45 140 0 140 0 0 -145 0 -145 -140 0 -140 0 0 -40 0 -40 330 0 330 0 0 40 0 39 -142 3 -143 3 -3 143 -3 142 146 0 145 0 0 45 0 45 -330 0 -330 0 0 -45z"/>
      <path d="M10 1750 l0 -220 40 0 39 0 3 88 3 87 288 3 287 2 0 45 0 45 -290 0 -290 0 0 85 0 85 -40 0 -40 0 0 -220z"/>
      <path d="M7843 391 c-48 -22 -63 -45 -63 -96 0 -51 33 -79 121 -105 90 -27 101 -35 97 -74 -2 -25 -11 -35 -41 -50 -50 -24 -94 -13 -122 31 -13 20 -28 32 -42 33 -27 0 -28 -11 -8 -54 22 -47 62 -66 135 -66 57 0 65 3 96 34 27 27 34 42 34 74 0 57 -29 88 -98 107 -100 28 -122 41 -122 74 0 34 15 49 58 57 42 9 91 -12 98 -42 4 -17 13 -24 30 -24 27 0 30 15 12 54 -26 58 -114 80 -185 47z"/>
      <path d="M8780 398 c-19 -7 -51 -29 -72 -50 -92 -92 -69 -261 45 -319 51 -27 138 -30 182 -7 55 29 105 102 105 153 l0 25 -105 0 c-98 0 -105 -1 -105 -20 0 -18 7 -20 80 -20 44 0 80 -2 80 -5 0 -4 -7 -20 -16 -36 -34 -67 -148 -86 -217 -37 -77 55 -75 193 4 251 62 45 173 26 208 -35 12 -20 24 -28 44 -28 l28 0 -23 42 c-41 74 -152 114 -238 86z"/>
      <path d="M13588 400 c-90 -27 -143 -99 -143 -195 0 -118 79 -198 196 -198 100 0 169 58 193 166 l6 27 -105 0 c-98 0 -105 -1 -105 -20 0 -18 7 -20 75 -20 89 0 96 -13 40 -66 -73 -69 -196 -49 -235 38 -26 56 -25 94 3 148 49 96 184 108 248 21 14 -19 32 -31 45 -31 21 0 22 2 12 30 -26 76 -143 126 -230 100z"/>
      <path d="M14153 391 c-48 -22 -63 -45 -63 -96 0 -51 33 -79 121 -105 90 -27 101 -35 97 -74 -2 -25 -11 -35 -41 -50 -50 -24 -94 -13 -122 31 -13 20 -28 32 -42 33 -27 0 -28 -11 -8 -54 22 -47 62 -66 135 -66 57 0 65 3 96 34 27 27 34 42 34 74 0 57 -29 88 -98 107 -100 28 -122 41 -122 74 0 34 15 49 58 57 42 9 91 -12 98 -42 4 -17 13 -24 30 -24 27 0 30 15 12 54 -26 58 -114 80 -185 47z"/>
      <path d="M3230 205 l0 -195 25 0 c25 0 25 1 25 90 l0 90 100 0 100 0 0 -90 c0 -89 0 -90 25 -90 l25 0 0 195 0 195 -25 0 c-25 0 -25 -1 -25 -80 l0 -80 -100 0 -100 0 0 80 c0 79 0 80 -25 80 l-25 0 0 -195z"/>
      <path d="M3830 253 c0 -129 3 -152 20 -180 54 -89 206 -89 260 0 17 28 20 51 20 180 l0 147 -25 0 -25 0 0 -145 c0 -141 -1 -147 -24 -170 -45 -45 -107 -45 -152 0 -23 23 -24 29 -24 170 l0 145 -25 0 -25 0 0 -147z"/>
      <path d="M4465 388 c-2 -7 -16 -87 -30 -178 -15 -91 -29 -173 -31 -182 -4 -14 1 -18 20 -18 29 0 28 -5 57 169 l22 134 24 -49 c13 -27 43 -93 68 -147 25 -54 49 -96 53 -93 5 3 37 68 72 145 35 77 65 139 66 138 2 -1 12 -59 23 -127 30 -177 27 -170 57 -170 17 0 24 5 21 13 -2 6 -17 94 -33 195 l-28 182 -26 0 c-24 0 -32 -12 -90 -140 -34 -77 -64 -140 -65 -140 -1 0 -31 63 -65 140 -56 123 -66 140 -87 140 -13 0 -25 -6 -28 -12z"/>
      <path d="M5197 212 c-42 -104 -77 -192 -77 -195 0 -4 11 -7 24 -7 20 0 28 9 42 45 l17 45 97 0 97 0 17 -45 c14 -36 22 -45 42 -45 13 0 24 3 24 8 0 4 -35 90 -78 192 -69 165 -81 185 -103 188 -23 3 -29 -8 -102 -186z m142 26 c17 -42 31 -79 31 -82 0 -3 -31 -6 -70 -6 -38 0 -70 3 -70 7 0 19 66 171 71 165 3 -4 20 -42 38 -84z"/>
      <path d="M5740 205 l0 -195 25 0 24 0 3 149 3 149 108 -149 c148 -204 147 -204 147 46 0 188 -1 195 -20 195 -19 0 -20 -7 -20 -145 0 -80 -3 -145 -7 -145 -5 1 -55 66 -112 145 -83 117 -108 145 -127 145 l-24 0 0 -195z"/>
      <path d="M6690 205 l0 -195 88 0 c74 0 96 4 138 25 73 37 99 81 99 170 0 61 -4 78 -27 112 -41 62 -94 83 -206 83 l-92 0 0 -195z m211 130 c90 -47 92 -200 4 -255 -24 -15 -50 -20 -99 -20 l-66 0 0 143 c0 79 3 147 8 151 11 12 119 -2 153 -19z"/>
      <path d="M7300 205 l0 -195 110 0 110 0 0 25 c0 25 -1 25 -85 25 l-85 0 0 65 0 65 75 0 c68 0 75 2 75 20 0 18 -7 20 -75 20 l-75 0 0 65 0 65 85 0 c78 0 85 2 85 20 0 19 -7 20 -110 20 l-110 0 0 -195z"/>
      <path d="M8330 205 l0 -195 25 0 25 0 0 195 0 195 -25 0 -25 0 0 -195z"/>
      <path d="M9310 206 l0 -196 25 0 24 0 3 151 3 151 110 -151 c86 -119 114 -151 132 -151 l23 0 0 195 0 195 -25 0 -24 0 -3 -147 -3 -148 -106 145 c-85 117 -111 146 -132 148 l-27 3 0 -195z"/>
      <path d="M10270 205 l0 -195 25 0 c24 0 25 2 25 75 l0 75 43 0 c42 0 43 0 87 -75 39 -65 48 -75 73 -75 15 0 26 3 24 7 -3 5 -23 39 -46 76 l-41 68 40 38 c32 30 40 45 40 74 0 95 -44 127 -177 127 l-93 0 0 -195z m169 144 c56 -20 70 -83 25 -118 -20 -16 -41 -21 -85 -21 l-59 0 0 75 0 75 44 0 c23 0 57 -5 75 -11z"/>
      <path d="M10820 205 l0 -195 116 0 c114 0 115 0 112 23 -3 21 -8 22 -90 25 l-88 3 0 64 0 65 80 0 c73 0 80 2 80 20 0 18 -7 20 -80 20 l-80 0 0 65 0 65 85 0 c78 0 85 2 85 20 0 19 -7 20 -110 20 l-110 0 0 -195z"/>
      <path d="M11360 214 c-41 -102 -77 -190 -79 -195 -2 -6 9 -9 25 -7 22 2 30 11 43 46 l15 42 97 0 97 0 18 -45 c14 -39 21 -45 45 -45 l28 0 -35 83 c-20 45 -56 133 -82 194 -42 101 -50 113 -72 113 -23 0 -31 -15 -100 -186z m142 21 l36 -85 -75 0 c-67 0 -74 2 -69 18 34 90 60 152 66 152 4 0 23 -38 42 -85z"/>
      <path d="M11910 204 l0 -196 99 4 c87 3 103 7 139 31 52 34 83 93 84 157 0 61 -8 86 -39 129 -39 51 -92 71 -195 71 l-88 0 0 -196z m202 129 c91 -53 94 -199 5 -253 -23 -14 -50 -20 -94 -20 l-63 0 0 151 0 152 60 -6 c33 -3 74 -14 92 -24z"/>
      <path d="M12510 205 l0 -195 25 0 25 0 0 195 0 195 -25 0 -25 0 0 -195z"/>
      <path d="M12860 205 c0 -187 1 -195 20 -195 18 0 20 8 22 153 l3 152 110 -152 c85 -118 115 -152 133 -153 l22 0 0 195 0 195 -25 0 -24 0 -3 -147 -3 -148 -105 147 c-81 114 -110 147 -127 148 l-23 0 0 -195z"/>
    </g>
  </IconBase>
);

export default LogoText;
