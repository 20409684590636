import styled from 'styled-components';

// Assets
import './App.css';

// Components
import Form from './components/Form';

// Styled Elements
const AppContainer = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 900px;
  min-height: calc(100vh - 4em);
  position: relative;
  width: 100%;

  @media screen and (max-width: 768px) {
    font-size: 0.6em;
  }
`;

function App() {
  return (
    <AppContainer className="App">
      <Form />
    </AppContainer>
  );
}

export default App;
