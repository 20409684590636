const StrategyData = {
  "To Respond": {
    label: "My energy is magnetic & always pulling things toward me to respond to"
  },
  // Option: "Wait for Recognition or an Invitation”
  "Wait for the Invitation": {
    label: "It's important my energy is recognized & invited in before I move into action",
    subtext: "Wait for Invitations"
  },
  // "To Wait a Lunar Cycle (28 days)"
  "Wait a Lunar Cycle": {
    label: "It's important I give myself space to feel into things before acting on them",
    subtext: "Wait a Lunar Cycle"
  },
  "To Inform": {
    label: "Its important I follow my creative bursts & inform others before acting on them"
  }
};

export default StrategyData;
