const EnergyTypeData = {
  "Generator": {
    label: "I am a life force!"
  },
  "Projector": {
    label: "I am a natural leader!"
  },
  "Reflector": {
    label: "I am a shapeshifter!"
  },
  "Manifestor": {
    label: "I am an innovator!"
  },
  "Manifesting Generator": {
    label: "I am a creative powerhouse!"
  }
};

export default EnergyTypeData;
