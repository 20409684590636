import styled from "styled-components";

// Styled Components
const Input = styled.input`
  appearance: none; // Reset for mobile user agent styles
  background-color: #FFFFFF;
  // border-radius: 0.6em;
  box-shadow: 0 4px 0.5em -0.6em #000000;
  color: #000000;
  font-family: "Carlito";
  font-size: 1.25em;
  line-height: 1.2;
  margin: 0 0 1.5em;
  padding: 1em;
  text-align: center;
  width: 100%;

  ::placeholder {
    color: #000000;
  }

  &:focus, &:focus-visible {
    outline: inset;
  }
`;

const BaseInput = ({ name, placeholder, inputRef, onChange, required = false, type = "text", value }) => {
  return (
    <Input
      id={name}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      ref={inputRef}
      required={required}
      type={type}
      value={value}
    />
  );
}

export default BaseInput;
