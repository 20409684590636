import styled from "styled-components";

// Colors
import { black } from "./utilities/brandColors";

const Container = styled.div`
  display: inline-block;
  position: relative;
  text-align: center;
  width: 100%;
`;

const ReturnLink = styled.a`
  color: ${black};
  cursor: pointer;
  display: inline-block;
  font-family: "Carlito-I";
  font-size: 1.313em;
  padding: 0.5em 1em;
`;

const Divider = () => {
  return (
    <Container>
      <ReturnLink href="https://www.thedesignofyou.com/" title="The Design of You">Back to main site</ReturnLink>
    </Container>
  );
}

export default Divider;
