import { useCallback, useState } from "react";
import styled from 'styled-components';
import _, { debounce } from "lodash";

// Colors
import { black, white } from "./utilities/brandColors";

// Components
import { H1 } from './elements/Headings';
import BaseInput from './elements/InputBase';
import SelectInput from "./elements/SelectInput";
import Header from "./Header";
import LocationList from "./LocationList";
import FormFooter from "./FormFooter";
import Result from "./Result";

// URLs
const bodygraphBaseApiUrl = "https://api.bodygraphchart.com/";
const bodygraphLocationsUrl = `${bodygraphBaseApiUrl}v210502/locations`; // 1/22/23: Older API version
const bodygraphHumanDesignUrl = `${bodygraphBaseApiUrl}v221006/hd-data`;

// Styled Elements
const FormElement = styled.form`
  display: inline-block;
  margin-bottom: 8em;
  max-width: 600px;
  position: relative;
  width: 100%;
`;

const SelectContainer = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;

  // @media (max-width: 680px) {
  //   grid-gap: 0;
  //   grid-template-columns: 1fr;
  // }
`;

const ButtonContainer = styled.div`
  bottom: 6px;
  display: inline-block;
  left: 0;
  position: absolute;

  @media screen and (max-width: 768px) {
    bottom: 0;
  }
`;

const DefaultTime = styled.button`
  appearance: none;
  background: none;
  color: ${black};
  cursor: pointer;
  font-family: "Carlito";
  padding: 0.25em;
  position: relative;
  text-decoration: underline;
`;

const InputContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const Submit = styled.button`
  background-color: ${black};
  // border-radius: 0.6em;
  box-shadow: 0 4px 0.5em -0.4em #000000;
  color: ${white};
  cursor: pointer;
  display: inline-block;
  font-family: "Bebas-Neue";
  font-size: 2em;
  padding: 0.75em 2.5em;
  text-transform: uppercase;
  transition: 0.3s ease-in-out all;

  &:hover {
    background-color: ${white};
    color: ${black};
  }
`;

const Form = ({}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [birthHour, setBirthHour] = useState("");
  const [birthMinute, setBirthMinute] = useState("");
  const [birthMeridiem, setBirthMeridiem] = useState("");
  const [birthLocation, setBirthLocation] = useState("");
  const [locations, setLocations] = useState([]);
  const [birthTimezone, setBirthTimezone] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  // const [formIsValid, setFormIsValid] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formResponse, setFormResponse] = useState({});
  const [formError, setFormError] = useState("");

  const api_key = process.env.REACT_APP_BODYGRAPH_API_KEY;

  const currentYear = new Date().getFullYear();
  const dayOptions = _.range(1, 32);
  const monthOptions = [
    { text: "January", value: "01" },
    { text: "February", value: "02" },
    { text: "March", value: "03" },
    { text: "April", value: "04" },
    { text: "May", value: "05" },
    { text: "June", value: "06" },
    { text: "July", value: "07" },
    { text: "August", value: "08" },
    { text: "September", value: "09" },
    { text: "October", value: "10" },
    { text: "November", value: "11" },
    { text: "December", value: "12" }
  ];
  const yearOptions = _.range((currentYear), (currentYear - 121));

  const pad = (num, places) => String(num).padStart(places, '0');
  const hourOptions = _.range(1, 13).map((num) => pad(num, 2));
  const minuteOptions = _.range(0, 60).map((num) => pad(num, 2));
  const meridiemOptions = ["AM", "PM"];

  const formatHour = (hour, meridiem) => {
    let hourInt = parseInt(hour);

    if (meridiem === "PM") {
      if (hourInt < 12) hourInt += 12;
    } else if (meridiem === "AM") {
      if (hourInt === 12) hourInt = "00";
    }

    return pad(hourInt.toString(), 2);
  };

  // Data Manipulation Functions ----------------------------------------------------------------------------------------
  const setTimeMidday = (event) => {
    setBirthHour("12");
    setBirthMinute("00");
    setBirthMeridiem("PM");
  };

  const fetchLocations = debounce((query) => {
    fetch(`${bodygraphLocationsUrl}?api_key=${api_key}&query=${query}`).then(
      response => response.json()
    ).then((data) => {
      setLocations(data);
    }).catch((error) => {
      setFormError(error);
    });
  }, 300);

  const debounceFetchLocations = useCallback(value => fetchLocations(value), []);

  const locationChange = (event) => {
    let value = event.target.value;
    setBirthLocation(value);
    debounceFetchLocations(value);
  };

  const selectLocation = (event, location, timezone) => {
    setBirthLocation(location);
    setBirthTimezone(timezone);
    setLocations([]);
  };

  // Submission Functions -----------------------------------------------------------------------------------------------
  const handleSubmit = (event) => {
    if (event.target.checkValidity()) {
      event.preventDefault();
      setFormSubmitting(true);
      const date = `${birthYear}-${birthMonth}-${birthDay} ${formatHour(birthHour, birthMeridiem)}:${birthMinute}`;

      fetch(`${bodygraphHumanDesignUrl}?api_key=${api_key}&date=${date}&timezone=${birthTimezone}`).then(
        response => response.json()
      ).then((data) => {
        setFormResponse(data);
        setFormSubmitting(false);

        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }).catch((error) => {
        setFormError(error);
        setFormSubmitting(false);
      });

      // Send name & email address to mailing list hook
      fetch("https://hooks.zapier.com/hooks/catch/12206785/bp7p13o/", {
        method: "POST",
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: emailAddress
        })
      });
    }
  };

  if (!_.isEmpty(formResponse)) {
    let fullName = `${firstName} ${lastName}`;
    return <Result fullName={fullName} result={formResponse} />;
  }

  return (
    <>
      <Header />

      <H1>Decode your Human Design</H1>
      <FormElement id="dyd-form" onSubmit={(event) => handleSubmit(event)}>
        <BaseInput name="first-name" onChange={(event) => setFirstName(event.target.value)} placeholder="First Name" required={true} value={firstName} />
        <BaseInput name="last-name" onChange={(event) => setLastName(event.target.value)} placeholder="Last Name" required={true} value={lastName} />

        <SelectContainer>
          <SelectInput
            name="birth-day"
            onChange={(event) => setBirthDay(event.target.value)}
            options={dayOptions}
            placeholder={"Birth Day"}
            required={true}
            value={birthDay}
          />
          <SelectInput
            name="birth-month"
            onChange={(event) => setBirthMonth(event.target.value)}
            options={monthOptions}
            placeholder={"Birth Month"}
            required={true}
            value={birthMonth}
          />
          <SelectInput
            name="birth-year"
            onChange={(event) => setBirthYear(event.target.value)}
            options={yearOptions}
            placeholder={"Birth Year"}
            required={true}
            value={birthYear}
          />
        </SelectContainer>

        <SelectContainer>
          <SelectInput
            name="birth-hour"
            onChange={(event) => setBirthHour(event.target.value)}
            options={hourOptions}
            placeholder={"Hour"}
            required={true}
            value={birthHour}
          />
          <SelectInput
            name="birth-minute"
            onChange={(event) => setBirthMinute(event.target.value)}
            options={minuteOptions}
            placeholder={"Minute"}
            required={true}
            value={birthMinute}
          />
          <SelectInput
            name="birth-meridiem"
            onChange={(event) => setBirthMeridiem(event.target.value)}
            options={meridiemOptions}
            placeholder={"AM / PM"}
            required={true}
            value={birthMeridiem}
          />
          <ButtonContainer>
            <DefaultTime onClick={(event) => setTimeMidday(event)} type="button">Birth Time Unknown</DefaultTime>
          </ButtonContainer>
        </SelectContainer>

        <InputContainer>
          <BaseInput
            name="birth-location"
            onChange={(event) => locationChange(event)}
            onFocus={(event) => locationChange(event)}
            placeholder="Birth Location"
            required={true}
            type="text"
            value={birthLocation}
          />
          <LocationList locations={locations} onClick={selectLocation} />
          <BaseInput name="birth-timezone" type="hidden" value={birthTimezone} />
        </InputContainer>

        <BaseInput name="email-address" onChange={(event) => setEmailAddress(event.target.value)} placeholder="Email Address" required={true} type="email" value={emailAddress} />

        <Submit type="submit">Submit</Submit>
      </FormElement>

      <FormFooter />
    </>
  );
}

export default Form;
