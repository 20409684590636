import styled from "styled-components";

// Assets
import { black } from "./utilities/brandColors";
import resultLogo from "../assets/images/dyd-logo-icon.png";
import IconRestart from "./svgs/IconRestart";

// Components
import { H1, H2, H3 } from "./elements/Headings";
import Footer from "./Footer";

// Data
import EnergyTypeData from "../data/EnergyTypeData";
import GiftTypeData from "../data/GiftTypeData";
import StrategyData from "../data/StrategyData";
import AuthorityData from "../data/AuthorityData";
import ProfileData from "../data/ProfileData";
import IncarnationCrossData from "../data/IncarnationCrossData";

// Styled Elements
const ResultContainer = styled.div`
  display: inline-block;
  margin-bottom: 6em;
  max-width: 680px;
  position: relative;
  width: 100%;
`;

const LogoLink = styled.a`
  background-image: url('${resultLogo}');
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 17.5em;
  margin-bottom: 4em;
  position: relative;
  width: 14.875em;
`;

const LineItem = styled.div`
  display: inline-block;
  margin-bottom: 2em;
  // padding: 0 5%;
  position: relative;
  width: 100%;
`;

const Restart = styled.a`
  align-items: center;
  color: ${black};
  display: inline-flex;
  font-family: "Carlito-I";
  font-size: 1.313em;
  justify-content: center;
  margin-bottom: 1.238em;
  padding: 0.5em 1em;
  position: relative;
  width: 100%;

  span {
    margin-right: 0.5em;
  }
`;

const Result = ({ fullName, result }) => {
  const properties = result["Properties"];
  const type = properties["Type"].id;
  const strategy = properties["Strategy"].id;
  const authority = properties["InnerAuthority"].id;
  const profileNum = properties["Profile"].id;
  const [profile1, profile2] = profileNum.split("/").map(string => string.trim());
  const incarnationCross = properties["IncarnationCross"].id.split("(")[0].trim();

  const renderSubtext = (object, key) => {
    var subtext = object.subtext;
    return subtext || key;
  };

  return (
    <>
      <ResultContainer>
        <LogoLink aria-label="The Design of You" href="https://www.thedesignofyou.com/" title="The Design of You" />
        <H1>{fullName}</H1>

        {EnergyTypeData[type] &&
          <LineItem>
            <H2>{EnergyTypeData[type].label}</H2>
            <H3>Energy Type: {renderSubtext(EnergyTypeData[type], type)}</H3>
          </LineItem>
        }

        {GiftTypeData[type] &&
          <LineItem>
            <H2>{GiftTypeData[type].label}</H2>
            <H3>Energy Type: {renderSubtext(GiftTypeData[type], type)}</H3>
          </LineItem>
        }

        {StrategyData[strategy] &&
          <LineItem>
            <H2>{StrategyData[strategy].label}</H2>
            <H3>Strategy: {renderSubtext(StrategyData[strategy], strategy)}</H3>
          </LineItem>
        }

        {AuthorityData[authority] &&
          <LineItem>
            <H2>{AuthorityData[authority].label}</H2>
            <H3>Authority: {renderSubtext(AuthorityData[authority], authority)}</H3>
          </LineItem>
        }

        {ProfileData[profileNum] &&
          <>
            <LineItem>
              <H2>{ProfileData[profileNum][profile1].label}</H2>
              <H3>Profile: {renderSubtext(ProfileData[profileNum][profile1], profileNum)}</H3>
            </LineItem>

            <LineItem>
              <H2>{ProfileData[profileNum][profile2].label}</H2>
              <H3>Profile: {renderSubtext(ProfileData[profileNum][profile2], profileNum)}</H3>
            </LineItem>
          </>
        }

        {IncarnationCrossData[incarnationCross] &&
          <LineItem>
            <H2>{IncarnationCrossData[incarnationCross].label}</H2>
            <H3>Incarnation Cross: {renderSubtext(IncarnationCrossData[incarnationCross], incarnationCross)}</H3>
          </LineItem>
        }
      </ResultContainer>

      <Restart href="/" title="Decode another design">
        <IconRestart fill={black} />
        Decode another design
      </Restart>

      <Footer />
    </>
  );
};

export default Result;
