import styled from 'styled-components';

// Assets
import { black } from './utilities/brandColors';
import hdChart from '../assets/images/hd-chart.png';

// Components
import { H2 } from './elements/Headings';
import Cta from './elements/Cta';

// Icons
import LogoText from './svgs/LogoText';
import FacebookIcon from './svgs/FacebookIcon';
import InstagramIcon from './svgs/InstagramIcon';

// Styled Elements
const FooterElement = styled.div`
  display: inline-block;
  font-size: 1.125em;
  max-width: 600px;
  position: relative;
  text-align: center;
  width: 100%;
`;

const H2Alt = styled(H2)`
  margin-bottom: 1em;
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6em;
  position: relative;
  width: 100%;
`;

const SocialLink = styled.a`
  cursor: pointer;
  display: inline-block;
  margin: 0 0.4em;
  position: relative;
`;

const Container = styled.div`
  display: inline-block;
  margin-bottom: 6em;
  position: relative;
  width: 100%;
`;

const LogoLink = styled.a`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const Tagline = styled.div`
  color: ${black};
  display: inline-block;
  font-family: "Carlito";
  font-size: 1.875em;
  padding: 2em;
  position: relative;
  text-align: center;
  width: 100%;
`;

function Footer() {
  return (
    <FooterElement>
      <Container>
        <Cta full={true} href="https://www.thedesignofyou.com/get-your-chart" text="Get Your Full Human Design Chart" />
        <Cta full={true} href="https://www.thedesignofyou.com/chart-summaries" text="Get Your Personalized HD Guidebook" />
        <Cta full={true} href="https://www.thedesignofyou.com/book-a-reading" text="Book a Reading" />
      </Container>

      <Container>
        <H2Alt className="alt">Created by</H2Alt>
        <LogoLink aria-label="The Design of You" href="https://www.thedesignofyou.com/" title="The Design of You">
          <LogoText fill={black} height="auto" width="25em" />
        </LogoLink>
      </Container>

      <SocialContainer>
        <SocialLink href="https://instagram.com/thedesignofyou" target="_blank" title="@TheDesignOfYou on Instagram">
          <InstagramIcon height="4em" width="4em" />
        </SocialLink>
        {/* <SocialLink href="https://twitter.com/thedesignofyou" target="_blank" title="@TheDesignOfYou on Twitter" /> */}
        {/* <SocialLink href="https://www.facebook.com/thedesignofyou" target="_blank" title="@TheDesignOfYou on Facebook">
          <FacebookIcon height="4em" width="4em" />
        </SocialLink> */}
      </SocialContainer>

      <Tagline>
        &copy; The Design Of You, LLC
      </Tagline>
    </FooterElement>
  );
}

export default Footer;
