import IconBase from "./IconBase";

const InstagramIcon = ({ fill = "#000000", height = "70px", width = "70px;" }) => (
  <IconBase
    fill={fill}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 998 998"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M650.4,328.5c-10.6,0-19.2,8.6-19.2,19.1c0,10.6,8.6,19.1,19.2,19.1c10.6,0,19.2-8.6,19.2-19.1 C669.5,337.1,660.9,328.5,650.4,328.5z" />
    <path d="M499,388.2c-61.1,0-110.8,49.7-110.8,110.8c0,61.1,49.7,110.8,110.8,110.8S609.8,560.1,609.8,499 C609.8,437.9,560.1,388.2,499,388.2z" />
    <path d="M589.8,246.4H408.2c-89.2,0-161.7,72.6-161.7,161.7v181.6c0,89.2,72.6,161.7,161.7,161.7h181.6 c89.2,0,161.7-72.6,161.7-161.7V408.2C751.6,319,679,246.4,589.8,246.4z M499,640c-77.7,0-141-63.2-141-141c0-77.7,63.2-141,141-141 c77.7,0,141,63.2,141,141C640,576.7,576.7,640,499,640z M650.4,397c-27.2,0-49.4-22.1-49.4-49.4c0-27.2,22.1-49.4,49.4-49.4 s49.4,22.1,49.4,49.4C699.7,374.9,677.6,397,650.4,397z" />
    <path d="M499,0C223.9,0,0,223.9,0,499s223.9,499,499,499s499-223.9,499-499S774.1,0,499,0z M781.8,589.8c0,105.8-86.1,192-192,192 H408.2c-105.8,0-192-86.1-192-192V408.2c0-105.8,86.1-192,192-192h181.6c105.8,0,192,86.1,192,192V589.8z" />
  </IconBase>
);

export default InstagramIcon;
