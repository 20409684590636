const AuthorityData = {
  // Sacral / Gut
  "Sacral": {
    label: "When I trust my gut, my life flows with satisfaction",
    subtext: "Sacral"
  },
  // "Solar Plexus / Emotional"
  "Emotional - Solar Plexus": {
    label: "When I wait for emotional clarity, the answer comes to me",
    subtext: "Solar Plexus"
  },
  // "Splenic / Instinctual"
  "Splenic": {
    label: "When I act on my instincts, everything falls into place",
    subtext: "Splenic"
  },
  // "Ego Projector"
  "Ego Projected": {
    label: "When my heart is in something, I am successful",
    subtext: "Ego Projector"
  },
  // "Ego Manifestor"
  "Ego Manifested": {
    label: "When my heart is in something, I am at peace",
    subtext: "Ego Manifestor"
  },
  // "Environmental (Mental)"
  "Environmental (Mental)": { // NEED TO CHECK API VALUE - NOT LISTED IN DOCUMENTATION https://bodygraphchart.com/docs/
    label: "When I'm around the right people and places, I make successful decisions",
    subtext: "Enviro(Mental) Projector"
  },
  // "G-Centers / Self-Projected"
  "Self Projected": {
    label: "When I speak my truth, I hear the answer in my voice",
    subtext: "Self-Projected"
  },
  // "No inner authority (lunar authority)"
  "None": {
    label: "With time, the universe pleasantly surprises me with the answer",
    subtext: "None"
  }
};

export default AuthorityData;
