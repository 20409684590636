import styled from "styled-components";
import { black } from "../utilities/brandColors";

const SvgContainer = styled.span`
  display: inline-block;
  height: ${props => props.height};
  max-width: ${props => props.width};
  position: relative;
  width: 100%;
`;

const Svg = styled.svg`
  display: inline-block;
  fill: ${props => props.fill};
  height: 100%;
  position: relative;
  width: 100%;

  &:hover {
    &, path {
      fill: ${props => props.hoverColor};
    }
  }

  path {
    fill: ${props => props.fill};
  }
`;

const IconBase = ({ className, children, fill = black, hoverColor = fill, height, width, viewBox, ...props }) => (
  <SvgContainer className={className} height={height} width={width}>
    <Svg fill={fill} hoverColor={hoverColor} viewBox={viewBox} {...props}>
      {children}
    </Svg>
  </SvgContainer>
);

export default IconBase;
